<template>
  <b-card
    class="card-app-design rooms-container"
    title="Manage Users"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-between mb-3">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-button
            variant="outline-primary"
            size="md"
            @click="handleOpenRegisterModal()"
          >
            Register
          </b-button>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: false,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleOpenViewUsersModal(props.row)">
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleOpenEditUsersModal(props.row)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleOpenManageUsersModal(props.row)">
                    <feather-icon
                      icon="SettingsIcon"
                      class="mr-50"
                    />
                    <span>Manage</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openManageUsersModal"
          title="Manage Users"
          size="sm"
          @ok.prevent="validationForm"
        >
          <b-row>
            <b-col sm="12">
              <div class="d-flex align-content-center justify-content-center">
                <b-alert
                  variant="warning"
                  show
                  class="py-1 px-1"
                >
                  <p>You're changing the status of {{ ' ' }}
                    <span class="text-primary">{{ UsersFormData.name }}</span>
                  </p>
                </b-alert>
              </div>
              <div class="mt-1">
                <b-form-select
                  v-model="selectedStatus"
                  :options="UsersStatusOptions"
                />
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterUsersDetailsModal"
          title="Users Details"
          size="lg"
          @ok.prevent="validationForm"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Name:</span><span class="text-info ml-1">{{ UsersFormData.name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Email:</span><span class="text-info ml-1">{{ UsersFormData.email }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Phone Number:</span><span class="text-info ml-1">{{ UsersFormData.phone_number }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Role:</span><span class="text-info ml-1">{{ UsersFormData.role }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Designation:</span><span class="text-info ml-1">{{ UsersFormData.designation }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterUsersModal"
          title="Register Users"
          size="lg"
          @ok.prevent="validationForm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="FullName"
                      rules="required"
                    >
                      <b-form-input
                        v-model="UsersFormData.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="UsersFormData.email"
                      placeholder="Enter Email"
                      type="email"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="UsersFormData.phone_number"
                      placeholder="Enter Phone Number"
                      type="tel"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b class="text-danger">data here should come from the database</b>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <b-form-select
                        v-model="RoleItem"
                        :options="RoleItemOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b class="text-danger">data here should come from the database</b>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Designation"
                      rules="required"
                    >
                      <b-form-select
                        v-model="DesignationItem"
                        :options="DesignationItemOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BAlert, BFormSelect, BModal, BForm, BPagination, BFormGroup, BFormInput, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import { required, email } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BBadge,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      log: [],
      pageLength: 3,
      openRegisterUsersModal: false,
      openRegisterUsersDetailsModal: false,
      openManageUsersModal: false,
      selectedStatus: null,
      RoleItem: null,
      DesignationItem: null,
      dir: false,
      required,
      email,
      UsersFormData: {},
      pages: ['3', '5', '10'],
      DesignationItemOptions: [
        { value: null, text: 'Select Designation', disabled: true },
        { value: 'Planting', text: 'Planting' },
        { value: 'Vegetative', text: 'Vegetative' },
      ],
      RoleItemOptions: [
        { value: null, text: 'Select Role', disabled: true },
        { value: 'Planting', text: 'Planting' },
        { value: 'Vegetative', text: 'Vegetative' },
      ],
      UsersStatusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'Active', text: 'Active' },
        { value: 'Inactive', text: 'Inactive' },
      ],
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Code',
          field: 'email',
        },
        {
          label: 'Date Planted',
          field: 'startDate',
        },
        {
          label: 'Quantity',
          field: 'salary',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/table_ssr')
      .then(res => { this.rows = res.data })
  },
  methods: {
    handleOpenRegisterModal() {
      this.openRegisterUsersModal = true
    },
    handleOpenViewUsersModal(item) {
      this.openRegisterUsersDetailsModal = true
      this.UsersFormData = item
    },
    handleOpenEditUsersModal(item) {
      this.openRegisterUsersModal = true
      this.UsersFormData = item
    },
    handleOpenManageUsersModal(item) {
      this.openManageUsersModal = true
      this.UsersFormData = item
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
